import React, { createContext, useContext } from 'react';
import { PageContextProviderProps } from './page-context-provider.types';

export const DataContext = createContext<PageContextProviderProps | undefined>(
  undefined
);

export const PageContextProvider: React.FC<{
  children: React.ReactNode;
  value: PageContextProviderProps;
}> = ({ children, value }) => {
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a PageContextProvider');
  }
  return context;
};

export default DataContext;
