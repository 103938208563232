import React from 'react';
import { PageContextProvider } from '@context/page-context-provider';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';
import QueryParamsProviderRoot from './src/utils/QueryParamsProviderRoot';

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <QueryParamsProviderRoot>{element}</QueryParamsProviderRoot>
  </ApolloProvider>
);

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => (
  <PageContextProvider value={props}>{element}</PageContextProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  const pathsToCheck = ['/explore', '/pt/saiba-mais', '/es/explora'];

  return !pathsToCheck.includes(pathname);
};
