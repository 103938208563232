import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
  cache: new InMemoryCache(),
  headers: {
    authorization: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  },
  fetch,
});
