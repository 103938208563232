import React from 'react';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import queryString from 'query-string';
import { QueryParamProvider } from 'use-query-params';

const QueryParamsProviderRoot = ({ children }) => (
  <QueryParamProvider
    adapter={ReachAdapter}
    options={{
      searchStringToObject: queryString.parse,
      objectToSearchString: queryString.stringify,
    }}
  >
    {children}
  </QueryParamProvider>
);

export default QueryParamsProviderRoot;
