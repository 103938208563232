exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-circular-example-page-js": () => import("./../../../src/templates/circular-example-page.js" /* webpackChunkName: "component---src-templates-circular-example-page-js" */),
  "component---src-templates-content-hub-page-js": () => import("./../../../src/templates/content-hub-page.js" /* webpackChunkName: "component---src-templates-content-hub-page-js" */),
  "component---src-templates-gc-csr-profile-page-js": () => import("./../../../src/templates/gc-csr-profile-page.js" /* webpackChunkName: "component---src-templates-gc-csr-profile-page-js" */),
  "component---src-templates-gc-gov-profile-page-js": () => import("./../../../src/templates/gc-gov-profile-page.js" /* webpackChunkName: "component---src-templates-gc-gov-profile-page-js" */),
  "component---src-templates-gc-ppu-profile-page-js": () => import("./../../../src/templates/gc-ppu-profile-page.js" /* webpackChunkName: "component---src-templates-gc-ppu-profile-page-js" */),
  "component---src-templates-gc-rmpcp-profile-page-js": () => import("./../../../src/templates/gc-rmpcp-profile-page.js" /* webpackChunkName: "component---src-templates-gc-rmpcp-profile-page-js" */),
  "component---src-templates-gc-rmpncp-profile-page-js": () => import("./../../../src/templates/gc-rmpncp-profile-page.js" /* webpackChunkName: "component---src-templates-gc-rmpncp-profile-page-js" */),
  "component---src-templates-gc-sppi-profile-page-js": () => import("./../../../src/templates/gc-sppi-profile-page.js" /* webpackChunkName: "component---src-templates-gc-sppi-profile-page-js" */),
  "component---src-templates-global-commitment-listing-page-js": () => import("./../../../src/templates/global-commitment-listing-page.js" /* webpackChunkName: "component---src-templates-global-commitment-listing-page-js" */),
  "component---src-templates-global-commitment-who-is-involved-page-js": () => import("./../../../src/templates/global-commitment-who-is-involved-page.js" /* webpackChunkName: "component---src-templates-global-commitment-who-is-involved-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-site-map-tsx": () => import("./../../../src/templates/site-map.tsx" /* webpackChunkName: "component---src-templates-site-map-tsx" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standard-page.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-standard-page-v-2-js": () => import("./../../../src/templates/standard-page-v2.js" /* webpackChunkName: "component---src-templates-standard-page-v-2-js" */),
  "component---src-templates-structure-page-js": () => import("./../../../src/templates/structure-page.js" /* webpackChunkName: "component---src-templates-structure-page-js" */),
  "component---src-templates-webflow-page-js": () => import("./../../../src/templates/webflow-page.js" /* webpackChunkName: "component---src-templates-webflow-page-js" */)
}

